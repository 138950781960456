import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener("DOMContentLoaded", () => this.#hideLoadingOverlay());
  }

  #hideLoadingOverlay() {
    this.element.classList.add('hidden');
  }
}
