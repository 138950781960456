import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["notification", "count"]
  static values = {
    delay: { type: Number, default: 10000 }, // delay showing alerts for 10 seconds
  }

  sendAlerts = false
  notificationCache = new Set

  connect() {
      setTimeout(() => this.sendAlerts = true, this.delayValue)
  }

  markAllAsRead() {
    setTimeout(() => {
      fetch("/user_notices/mark_all_read")
        .then((r) => {
          this.notificationTargets.forEach((n) => n.dataset.unread = "false")
          this.countTarget.classList.add("hidden")
        })
        .then(() => this.dispatch("read"))
    }, 2000)
  }

  notificationTargetConnected(element) {
    if(this.#sendAlert(element)) {
      // This isn't really great, but we want to target the div inside the notification, so we are tying to markup
      alertify.notify(element.querySelector("div").innerHTML, 'default', 8)
    }
    this.notificationCache.add(element.dataset.id)
  }

  #sendAlert(element) {
    return this.sendAlerts && element.dataset.unread === "true" && !this.notificationCache.has(element.dataset.id)
  }
}