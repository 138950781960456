import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

import "../controllers/index";

config.mutateApproach = "sync";

// TODO: Switch to a custom kit where we don't have to list every icon in use
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCircleCheck,
  faCircleUser,
  faClose,
  faFileInvoiceDollar,
  faGear,
  faMagnifyingGlass,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

import {
  faClock as faRegularClock,
  faTrashCan as faRegularTrashCan,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCircleCheck,
  faCircleUser,
  faClose,
  faFileInvoiceDollar,
  faGear,
  faMagnifyingGlass,
  faPlus,

  faRegularClock,
  faRegularTrashCan
);

dom.watch();